.arrow {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    position: relative;
    margin: 0 1rem;


    span {
        top: .5rem;
        position: absolute;
        width: .75rem;
        height: .1rem;
        background-color: #575757;
        display: inline-block;
        transition: all .2s ease;

        &:first-of-type {
            left: 0;
            transform: rotate(45deg);
        }

        &:last-of-type {
            right: 0;
            transform: rotate(-45deg);
        }

    }

    &.active {

        span:first-of-type {
            transform: rotate(-45deg);
        }

        span:last-of-type {
            transform: rotate(45deg);
        }
    }


}