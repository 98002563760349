.box {
    min-width: 80px;
    height: 80px;
    background: linear-gradient(45deg, #F2F2F2 50%, #F2C879 50%);
}

.box2 {
    min-width: 80px;
    height: 80px;
    background: linear-gradient(-45deg, #F2F2F2 50%, #F2C879 50%);
}