*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: -10;
  width: 100vh;
  max-width: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}

.container1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: -10;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 35%;
  transform:  rotate(-141deg);

 
}
.container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: -10;
  width: 100%;
  position: fixed;
  top: 50%;
  right: 35%;
  transform:  rotate(-141deg); 
}

.container3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: -10;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 20%;
  transform: translateY(-50%) rotate(-180deg);
  opacity: 0.3;
}


.txt {
  white-space: nowrap;
  font-size: 44px;
  line-height: 44px
}

.t1 {
  color: #575757;
}
.t2 {
  transform: rotate(180deg);
}


@keyframes scrollTxt {
  0%{
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0%, 0);
  }
}

@media screen and (max-width: 1280px){

  .container1 {
    top: 35%;
  }

  .container2{
    top: 80%;
    right: 0%;
  }
}