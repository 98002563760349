.triangle-full-right .first-triangle-one{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
    background-color: 
}

.triangle-full-right:hover .first-triangle-one{
    background-color: #575757;

}

.triangle-full-right .first-triangle-two{
    -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    background-color: #F2F2F2;
}


.triangle-full-right:hover .first-triangle-third{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
    background-color: #F2C879;

}

.triangle-full-right:hover .first-triangle-fourth{
    -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%); 
    background-color: #575757;

}

.triangle-full-right:hover .second-triangle-one{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
    background-color: #575757;


}

.triangle-full-right:hover .second-triangle-two{
    -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    background-color: #F2C879;

}


.triangle-full-right:hover .second-triangle-third{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
    background-color: #F2C879;

}

.triangle-full-right:hover .second-triangle-fourth{
    -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%); 
    background-color: #575757;

}



.triangle-full-right:hover .third-triangle-one{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
    background-color: #575757;


}

.triangle-full-right:hover .third-triangle-two{
    -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    background-color: #F2C879;

}


.triangle-full-right:hover .third-triangle-third{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
    background-color: #F2C879;

}

.triangle-full-right:hover .third-triangle-fourth{
    -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%); 
    background-color: #575757;

}





.summoner-triangle-full-right .second-triangle-one{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%); 
}

.summoner-triangle-full-right:hover .second-triangle-one{
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
    clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
    background-color: #575757;

}

.summoner-triangle-full-right .second-triangle-two{
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
    clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
    background-color: #F2C879;
}


.summoner-triangle-full-right:hover .second-triangle-fourth{
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    background-color: #F2F2F2 !important;
}

.summoner-triangle-full-right .second-triangle-fourth{
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    background-color: #F2F2F2 !important;
}

.summoner-triangle-full-right:hover .second-triangle-third{
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
    clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
    background-color: #575757;

}


.summoner-triangle-full-right:hover .first-triangle-one{
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
}
.summoner-triangle-full-right:hover .first-triangle-two{
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
        background-color: #575757;
}


.summoner-triangle-full-right:hover .first-triangle-third{
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);

}

.summoner-triangle-full-right:hover .first-triangle-fourth{
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);
clip-path: polygon(0 100%, 0 0, 100% 0, 0% 100%);

    background-color: #575757;

}
